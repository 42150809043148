// Base URLS
//https://app.inventorygraphics.com/

import { useCallback, useState } from "react";

// Localhost
export const baseURL = "https://dev.inventorygraphics.com/";
export const baseURLWithoutSlash = "https://dev.inventorygraphics.com";

export const userCreateEndPoint = baseURL + "auth/users/"
export const activationURL = baseURL + "auth/users/activation/"
export const forgotPassURL = baseURL + "auth/users/reset_password/"
export const reSendEmailURL = baseURL + "auth/users/resend_activation/"
export const LOG_IN_JWT = baseURL + "auth/jwt/create/"
export const getUserData = baseURL + "auth/users/me/"
export const userPreferences=baseURL+"api/my/preferences/"
export const refreshToken=baseURL+"auth/jwt/refresh/"
export const createNewPassword=baseURL+"auth/users/reset_password_confirm/"
export const adminLink=baseURL+"fr/admin/users/"
export const sendCsvUser=baseURL+"fr/admin/send-csv/"
export const singleProcessedData=baseURL+"processed/images/"
export const allProcessedData=baseURL+"fr/admin/users/csv_processed/"
export const allProcessedImage = baseURL +"fr/admin/users/processed/"
export const allReorderImage = baseURL +"fr/admin/images/reorder/"
export const saveProcessedImage=baseURL+"processed/edit/"
export const getSummary=baseURL+"summary/"
export const getProcessedData=baseURL + "processed/get_data/"
export const getImageSlider =baseURL+"fr/admin/get-image/"
export const getInventoryImage = baseURL +"fr/admin/inventory-images/"
export const getLatestInventory = baseURL +"fr/admin/latest_csv_processed"
export const getSingleImage = baseURL +"fr/admin/get_single_image/"
export const getRemovedImage = baseURL +"removed/images/"
export const ReplaceImage = baseURL +"processed/replace/"
export const convertImage = baseURL +"fr/admin/convert-image/"
export const uploadImages = baseURL+"fr/admin/upload-images/"
export const markImages = baseURL+"fr/admin/mark-image/"
export const startManualScheduler = baseURL+"fr/admin/start-process/"
export const managerLink=baseURL+"client/get-clients/"
export const managerEditLink=baseURL+"client/edit/"
export const managerAddLink=baseURL+"client/register/"
export const getInventoryReview = baseURL +"fr/admin/review-images/"
export const getAllDealers = baseURL +"fr/admin/get-dealers/"
export const uploadNewImage = baseURL +"fr/admin/upload-collage/"
export const deactivateUser = baseURL +"fr/admin/deactivate-user/"
export const allManualImage = baseURL +"fr/admin/manually-added/"
export const replaceManualImage = baseURL +"fr/admin/replace-collage/"
export const addImageCsv = baseURL +"fr/admin/add-image-csv/"
export const getFrameImages = baseURL +"fr/admin/frame-images/"
export const searchRecords = baseURL +"fr/admin/search/"
export const createCollage = baseURL +"fr/admin/create-collage/"
export const addFrameImage = baseURL +"fr/admin/add-frame-image/"
export const updateFrameImage = baseURL +"fr/admin/update-frame-image/"
export const getOverlayImages = baseURL +"fr/admin/overlay-images/"
export const addOverlayImage = baseURL +"fr/admin/add-overlay-image/"
export const updateOverlayImage = baseURL +"fr/admin/update-overlay-image/"
export const updateOverlayStatus = baseURL +"fr/admin/active-inactive-overlay/"
export const removeRecentRecord = baseURL +"fr/admin/remove_recent_record/"
export const updateDealerID = baseURL +"client/update-dealer-id/"
export const resendRecord = baseURL +"fr/admin/resend-to-csv/"
export const startSingleProcess = baseURL +"fr/admin/start-single-process/"
export const downloadSummary = baseURL +"summary/download/"

export const imageLink="https://dev.inventorygraphics.com/media/"
// export const FtpUserPreferences=baseURL+"api/my/user_ftp_details/"
export function useForceUpdate() {
    const [, setTick] = useState(0);
    const update = useCallback(() => {
        setTick(tick => tick + 1);
    }, [])
    return update;
}
